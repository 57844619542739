/**
 *
 * @param {string} defaultDeviceId - default device id
 * @returns {string} - deviceId
 */
export default function getDeviceIdFromPath(defaultDeviceId) {
  const { pathname } = window.location;
  const selectedDeviceId = pathname.split('/')[2]
    ? pathname.split('/')[2] : defaultDeviceId;
    // throw error if selectedDeviceId is not found
  if (!selectedDeviceId) {
    throw new Error('Device Id not found in the path');
  }
  return selectedDeviceId;
}
