<template>
  <div class="row">
    <div
      id="device-selector"
      class="px-10 gy-10"
    >
      <label for="deviceSelector">Device</label>
      <select
        id="deviceSelector"
        v-model="selectedDeviceId"
      >
        <option
          v-for="device in deviceOptions"
          :key="device.value"
          :value="device.value"
        >
          {{ device.text }}
        </option>
      </select>
      <label for="date">Date</label>
      <input
        id="date"
        v-model="selectedDate"
        type="date"
        name="event-date"
      >
      <button
        id="submitBtn"
        class="btn btn-primary"
        @click="handleSubmit"
      >
        Submit
      </button>
    </div>
  </div>
</template>

<script>
/**
 * @typedef {import("../../types/deviceDetails").DeviceDetails} DeviceDetails
 *  @typedef {import("../../types/eventBusEventDetail")
 * .submitBtnClickEventDetail} submitBtnClickEventDetail
 */

import {
  format, sub, isValid, parse,
} from 'date-fns';

import { publish, BUS_EVENT_TYPES, subscribe } from '../modules/eventBus.js';
import { getDeviceDetails, getAllEvents } from '../modules/apiService.js';
import avlEventTypes from '../helpers/eventTypes.js';
import getDeviceIdFromPath from '../helpers/router.js';

export default {
  data() {
    return {
      selectedDeviceId: null,
      selectedDate: null,
      /** @type {DeviceDetails[]} */
      deviceDetails: [],
    };
  },
  computed: {
    deviceOptions() {
      return this.deviceDetails.map((device) => ({
        value: String(device.calampDeviceId),
        text: String(device.calampDeviceId),
      }));
    },
  },
  mounted() {
    this.setupDateComponent();
  },

  created() {
    subscribe(BUS_EVENT_TYPES.userLoggedIn, async () => {
      this.deviceDetails = await getDeviceDetails();
      const defaultDeviceId = this.deviceDetails[0].calampDeviceId;
      this.selectedDeviceId = getDeviceIdFromPath(defaultDeviceId);
    });

    window.addEventListener('popstate', this.onPopState);
  },
  beforeUnmount() {
    window.removeEventListener('popstate', this.onPopState);
  },
  methods: {
    onPopState() {
      this.setupDateComponent();
      if (typeof this.handleSubmit === 'function') {
        this.handleSubmit();
      }
    },
    setupDateComponent() {
      const dateFormat = 'yyyy-MM-dd';
      const yeterdayDate = format(sub(new Date(), { days: 1 }), dateFormat);
      let dateToUse = yeterdayDate;

      try {
        const urlSegments = window.location.pathname.split('/');

        // Get the fourth segment (date segment)
        const dateSegment = urlSegments[3];
        if (dateSegment) {
          const parsedDate = parse(dateSegment, dateFormat, new Date());
          dateToUse = isValid(parsedDate) ? format(parsedDate, dateFormat) : yeterdayDate;
        }
        // Use date-fns to parse and validate the date
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        // Set the date input value
        this.selectedDate = dateToUse;
      }
    },

    handleSubmit() {
      const spinnerContainer = document.querySelector('#spinner-container');
      if (!(spinnerContainer instanceof HTMLDivElement)) {
        throw new Error('Spinner container not found');
      }
      const { selectedDeviceId, selectedDate } = this;

      publish(BUS_EVENT_TYPES.submitBtnClicked, {
        selectedDeviceId,
        selectedDate,
      });

      window.history.pushState({}, '', `/device/${selectedDeviceId}/${selectedDate}`);

      publish(BUS_EVENT_TYPES.loading);
      if (!(spinnerContainer instanceof HTMLDivElement)) {
        throw new Error('Spinner container not found');
      }
      spinnerContainer.hidden = false;

      getAllEvents(selectedDeviceId, selectedDate)
        .then((avlEvents) => {
          if (!avlEvents || !avlEvents.length) {
            publish(BUS_EVENT_TYPES.dataLoaded, { avlEvents: [] });
            return;
          }

          const filteredAvlEvents = avlEvents.filter(
            (event) => Object.values(avlEventTypes).includes(event.eventType),
          );

          publish(BUS_EVENT_TYPES.dataLoaded, {
            avlEvents: filteredAvlEvents,
            selectedDeviceId,
            selectedDate,
          });
        })
        .catch((error) => {
          console.log(error);
          Sentry.captureException(error);
        })
        .finally(() => {
          spinnerContainer.hidden = true;
        });
    },

  },

};
</script>
