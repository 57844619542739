<template>
  <div class="col-sm-6">
    <h4>Device Details</h4>
    <dl class="row">
      <dt class="col">
        Asset Number
      </dt>
      <dd
        id="assetNumberDD"
        class="col"
      >
        {{ selectedDevice.assetNumber }}
      </dd>
    </dl>
    <dl class="row">
      <dt class="col">
        CalAmp Number
      </dt>
      <dd
        id="calAmpNumberDD"
        class="col"
      >
        {{ selectedDevice.calampDeviceId }}
      </dd>
    </dl>
    <dl class="row">
      <dt class="col">
        Serial Number
      </dt>
      <dd
        id="serialNumberDD"
        class="col"
      >
        {{ selectedDevice.serialNumber }}
      </dd>
    </dl>
  </div>
</template>
<script>
/**
 * @typedef {import("../../types/deviceDetails").DeviceDetails} DeviceDetails
 *  @typedef {import("../../types/eventBusEventDetail")
 * .submitBtnClickEventDetail} submitBtnClickEventDetail
 */

import { BUS_EVENT_TYPES, subscribe } from '../modules/eventBus.js';
import { getDeviceDetails } from '../modules/apiService.js';

export default {

  data() {
    return {
      selectedDevice: {
        assetNumber: '',
        calampDeviceId: '',
        serialNumber: '',
      },
    };
  },
  mounted() {
    subscribe(BUS_EVENT_TYPES.submitBtnClicked, this.hydrateSelectedDeviceInfo);
  },

  methods: {
    /**
     * @param {submitBtnClickEventDetail} eventType - event type
     */
    async  hydrateSelectedDeviceInfo(eventType) {
      const { selectedDeviceId } = eventType.detail;
      this.selectedDevice = await this.getDevice(selectedDeviceId);
    },

    /**
     * Retrieves a device from the deviceList based on the device ID.
     * @param {string} deviceid - The ID of the device.
     * @returns {Promise<DeviceDetails>} - The device object.
     */
    async  getDevice(deviceid) {
      const deviceDetails = await getDeviceDetails();

      // throw error if deviceid is not a number
      if (deviceDetails.length === 0) throw new Error('No devices found');

      const matchingDevice = deviceDetails.find(
        (device) => device.calampDeviceId === String(deviceid),
      );
      if (!matchingDevice) throw new Error(`No device found with id ${deviceid}`);
      return matchingDevice;
    },

  },
};
</script>
